<template lang="pug">
div.select-options-wrapper
  BackCircle(@click="$emit('clickBack')")
  div.select-options-title {{title}}
  slot
</template>

<script>
export default {
  name: "StepsContainer",
  components: {
    NextStepBtn: () => import('@/components/steps-faceless/NextStepBtn.vue'),
    BackCircle: () => import('@/components/steps-faceless/BackCircle.vue'),
  },
  props: {
    title: {
      type: String,
      default: ''
    },
  }
}
</script>

<style scoped lang="sass">

</style>